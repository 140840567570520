import {useState} from 'react';
import {ReportRequest} from "../types/ReportLibraryTypes";
import Spinner from '../../../common/components/Spinner';
import {DeleteFileIcon, DownloadFileIcon, InfoIcon} from "../../../common/components/RtpIcons";
import {Tooltip} from "primereact/tooltip";
import {HttpMethod, RtpApi} from "../../../common/services/RtpApi";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';

interface ReportRequestRowProps {
    request: ReportRequest;
    reportName: string;
    onDeleteRequest: () => void;
}

const ReportRequestRow: React.FC<ReportRequestRowProps> = ({
    request,
    reportName,
    onDeleteRequest
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const formatAge = (date: Date | undefined) => {
        if (!date) {
            return '-';
        }
        if (typeof date === 'string') {
            date = new Date(date);
        }
        if (!(
            date instanceof Date) || isNaN(date.getTime())) {
            return '-';
        }
        if (!date || isNaN(date.getTime())) {
            return '-';
        }
        const now = new Date();
        const diffMs = now.getTime() - date.getTime(); // difference in milliseconds
        const diffMinutes = Math.round(diffMs / 60000); // difference in minutes
        const diffHours = Math.round(diffMs / 3600000); // difference in hours
        const diffDays = Math.round(diffMs / 86400000); // difference in days
        const diffMonths = Math.round(diffMs / 2628000000); // approximate difference in months

        if (diffMinutes < 60) {
            return `${diffMinutes} minutes ago`;
        } else if (diffHours < 24) {
            return `${diffHours} hours ago`;
        } else if (diffDays < 30) {
            return `${diffDays} days ago`;
        } else if (diffMonths < 12) {
            return `${diffMonths} months ago`;
        } else {
            const diffYears = Math.round(diffMonths / 12);
            return `${diffYears} years ago`;
        }
    };

    const cleanFileName = (fileName: string | undefined) => {
        return fileName ? fileName.replace(/^[^,]*, /, '').replace(/-[0-9a-f]{32}\./i, '.') : '';
    };

    const handleDownload = async (event: React.MouseEvent) => {
        event.preventDefault();
        setIsDownloading(true);
        if (!request.downloadUri) {
            console.error('Download URI not found');
            setIsDownloading(false);
            return;
        }
        try {
            const downloadUri = new URL(request.downloadUri).href;
            const response = await RtpApi.request<Blob>(downloadUri, {method: HttpMethod.GET}, 'blob');
            const downloadUrl = window.URL.createObjectURL(RtpApi.extractData(response));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = cleanFileName(request.fileName) || 'Download';
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        }
        catch (error) {
            setIsDownloading(false);
            console.error('Error downloading file:', error);
        }
    }

    const downloadButton = request.status.key === 'Completed' ? (
        <button
            onClick={handleDownload}
            title="Download report"
            className="flex gap-2 justify-center items-center px-4 py-2 rounded border bg-[#008D76] text-xs uppercase text-gray-50 opacity-90 transition-opacity hover:opacity-100 hover:shadow-md"
            style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                height: 'auto'
            }}
        >
            {isDownloading ? (
                <div style={{
                    marginRight: '8px',
                    visibility: 'visible'
                }}>
                    <Spinner ExcludeText={true} lightColor={true}/>
                </div>) : <DownloadFileIcon/>}
            <span>Download</span>
        </button>) : (
        <button
            disabled
            title="Report not available for download"
            className="flex gap-2 justify-center items-center px-4 py-2 rounded border bg-gray-300 text-xs uppercase text-gray-500 opacity-50 cursor-not-allowed"
            style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                height: 'auto'
            }}
        >
            <DownloadFileIcon/>
            <span>Download</span>
        </button>);

    const handleDelete = async (event: React.MouseEvent) => {
        event.preventDefault();
        setIsDeleting(true);
        try {
            await RtpApi.request(`/DataQuery/Requests/${request.reportRequestId}`, {method: HttpMethod.DELETE});
            onDeleteRequest();
        }
        catch (error) {
            console.error('Error hiding report request:', error);
        }
        finally {
            setIsDeleting(false);
        }
    }

    const deleteButton = <button
        onClick={handleDelete}
        title="Delete this report"
        className="flex gap-2 justify-center items-center px-4 py-2 rounded border bg-[#008D76] text-xs uppercase text-gray-50 opacity-90 transition-opacity hover:opacity-100 hover:shadow-md"
    >
        {!isDeleting && <DeleteFileIcon/>}
        {isDeleting && <Spinner ExcludeText={true} lightColor={true}/>}
        <span>Delete</span>
    </button>;

    let statusIndicator;

    switch (request.status.key) {
        case 'Completed':
            statusIndicator = <FontAwesomeIcon className='mr-2 fa-lg' icon={faCheck} color='green'/>; // Green check
                                                                                                      // for completed
            break;
        case 'Failed':
            statusIndicator = <FontAwesomeIcon className='mr-2 fa-lg' icon={faXmark} color='red'/>; // Red X for failed
            break;
        default:
            statusIndicator = <span className='mr-1'><Spinner ExcludeText={true}/></span>;
            break;
    }

    const tooltipContent = (parameters: Record<string, string> | undefined): string => {
        if (!parameters) return '';
        const listItems = Object.entries(parameters)
            .map(([key, value]) => `<li><strong>${key}</strong>: ${value}</li>`);
        return `<ul>${listItems.join("")}</ul>`;
    };

    return (
        <div className="grid gap-4 items-center border rounded p-2"
             style={{gridTemplateColumns: 'minmax(200px, auto) minmax(230px, auto) 1fr'}}>
            <div className="flex items-center gap-2">
                <div>{reportName}</div>
                {request.parameters &&
                    (
                        <div className="flex items-center text-gray-400 cursor-pointer">
                            <InfoIcon className="w-4 h-4"/>
                            <Tooltip target={'#paramTooltip_' + request.reportRequestId} autoHide={false} event='hover'>
                                <div dangerouslySetInnerHTML={{__html: tooltipContent(request.parameters)}}></div>
                            </Tooltip>
                        </div>)}
            </div>
            <div className="flex status-container items-center">
                {statusIndicator}
                {request.status.displayName}
                {request.completedAt &&
                    request.status.key ===
                    'Completed' &&
                    (
                        <span>, {formatAge(request.completedAt)}</span>)}
            </div>
            <div className="flex-grow flex items-center justify-start">
                {downloadButton}
                {deleteButton}
                <div className="flex-1 text-left pl-2">{cleanFileName(request.fileName)}</div>
            </div>
        </div>);
};

export default ReportRequestRow;
