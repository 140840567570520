import React from "react";
import {GridColumn} from "../../common/types/GridColumn";
import EntityGrid from "./EntityGrid";
import {Company} from "../../common/types/Company";
import {DataTableSortMeta} from "primereact/datatable";

const CompanyGrid: React.FC = () => {
    const columns: GridColumn[] = [
        {
            field: 'name',
            name: 'name',
            label: 'Name',
            width: 200,
            sortable: true,
            filterable: true,
            dataType: 'text',
            defaultFilter: {
                value: null,
                matchMode: 'contains'
            }
        }, {
            field: 'firstYearWithTbg',
            name: 'firstYearWithTbg',
            label: 'First Year with TBG',
            width: 200,
            sortable: true,
            filterable: true,
            dataType: 'numeric',
            defaultFilter: {
                value: null,
                matchMode: 'equals'
            }
        }, {
            field: 'currentPrimaryBusinessObjective',
            name: 'currentPrimaryBusinessObjective',
            label: 'Primary Objective',
            width: 200,
            sortable: true,
            filterable: true,
            dataType: 'text',
            defaultFilter: {
                value: null,
                matchMode: 'contains'
            }
        }];

    const sort: DataTableSortMeta[] = [
        {
            field: 'name',
            order: 1
        }];

    return (
        <div>
            <EntityGrid<Company> entityName={"company"} additionalColumns={columns} defaultSort={sort}/>
        </div>);
};

export default CompanyGrid;