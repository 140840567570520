class DebounceService {
    /**
     * Debounce function to limit the rate at which a function is executed.
     * @param func - The function to debounce.
     * @param delay - The delay in milliseconds.
     * @returns A debounced version of the input function.
     */
    static debounce<T extends (...args: unknown[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
        let timer: ReturnType<typeof setTimeout>;

        return function (...args: Parameters<T>) {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }
}

export default DebounceService;
