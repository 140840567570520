const Spinner = (props: { ExcludeText?: boolean, lightColor?: boolean }) => {
    const spinnerClass = props.lightColor ? 'border-gray-50' : 'border-[#008D76]';

    return ( 
        <b className="flex gap-2 text-xs text-gray-500 items-center">
            {props.ExcludeText ? '' : `Loading... `}
            <b className={`[animation-duration:.66s] animate-spin border-4 ${spinnerClass} border-t-transparent h-6 rounded-full w-6`}></b>
        </b>
    );
}
 
export default Spinner;