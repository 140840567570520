import React, {useEffect, useState} from "react";
import {SelectItem} from "primereact/selectitem";
import {RtpApi} from "../../common/services/RtpApi";
import {Status} from "../../common/types/Status";
import {GridColumn} from "../../common/types/GridColumn";
import {ColumnEditorOptions} from "primereact/column";
import GridDropDown from "../../common/components/GridDropDown";
import {EntityService} from "../../common/services/EntityService";
import VirtualScrollerGrid from "../../common/components/VirtualScrollerGrid";
import {IApiEntity} from "../../common/types/IApiEntity";
import {Company} from "../../common/types/Company";
import {DataTableSortMeta} from "primereact/datatable";

interface EntityGridProps {
    entityName: string;
    additionalColumns?: GridColumn[];
    defaultSort?: DataTableSortMeta[];
}

const EntityGrid = <T extends IApiEntity>({
    entityName,
    additionalColumns,
    defaultSort
}: EntityGridProps): React.JSX.Element => {
    const [statuses, setStatuses] = useState<SelectItem[]>([]);

    useEffect(() => {
        RtpApi.request<Status[]>(`/entity/status/${entityName}`)
            .then((response) => {
                const selectItems = RtpApi.extractData(response)
                    .map((status) => (
                        {
                            label: status.label,
                            value: status.statusId
                        }));
                setStatuses(selectItems);
            })
            .catch((error) => console.error(`Failed to load ${entityName} statuses`, error));
    }, [entityName]);

    const defaultColumns: GridColumn[] = [
        {
            field: 'id',
            name: 'id',
            label: `${entityName.charAt(0).toUpperCase() + entityName.slice(1)} ID`,
            width: 100,
            sortable: true,
            filterable: true,
            dataType: 'uuid',
            defaultFilter: {
                value: null,
                matchMode: 'equals'
            }
        }, {
            field: 'statusId',
            name: 'status',
            label: 'Status',
            width: 200,
            body: (rowData: Company) => rowData?.status?.label || '',
            sortable: true,
            filterable: true,
            dataType: 'uuid',
            filterField: 'status.label',
            editor: (options: ColumnEditorOptions) => (
                statuses.length > 0 ? (
                    <GridDropDown options={options} values={statuses}/>) : <span>Loading...</span>),
            defaultFilter: {
                value: null,
                matchMode: 'contains'
            }
        }];
    // TODO: Searching via nav properties

    const columns: GridColumn[] = [
        ...defaultColumns,
        ...(
            additionalColumns || [])];

    const fetchData = async (limit: number, offset: number, sortString: string, filters: string) => {
        const response = await EntityService.getEntities<T>(entityName, limit, sortString, offset, true, filters);
        return {
            data: response.data,
            totalCount: response.totalCount
        };
    };

    const sort: DataTableSortMeta[] = defaultSort || [
        {
            field: 'id',
            order: 1
        }];

    return <div>
        <h2 className="text-xl font-semibold mb-4">{entityName.charAt(0).toUpperCase() + entityName.slice(1)} List</h2>
        <VirtualScrollerGrid<T> columns={columns} fetchData={fetchData} defaultSortMeta={sort}/>
    </div>;
};

export default EntityGrid;
