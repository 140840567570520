import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {RtpApi} from "../services/RtpApi";

interface ConfigType {
    apiUri: string;
    apiKey?: string;
    iframeOrigin: string;
    useMockToken: boolean;
}

const ConfigContext = createContext<ConfigType | undefined>(undefined);

export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('useConfig must be used within a ConfigProvider that has finished loading');
    }
    return context;
};

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [config, setConfig] = useState<ConfigType | undefined>(undefined);  // Start with undefined
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const mockApiUri = process.env.REACT_APP_MOCK_API_URI;
        const mockApiKey = process.env.REACT_APP_MOCK_API_KEY;
        const mockOrigin = process.env.REACT_APP_MOCK_ORIGIN;

        if (mockApiUri && mockApiKey) {
            const mockConfig = {
                apiUri: mockApiUri,
                apiKey: mockApiKey,
                iframeOrigin: mockOrigin,
                useMockToken: true
            };
            setConfig(mockConfig);
            RtpApi.setUri(mockConfig.apiUri);
            RtpApi.setApiKey(mockConfig.apiKey);
            window.parent.postMessage('ready', mockConfig.iframeOrigin);
            setLoading(false);
        } else {
            fetch('/appConfig/clientConfig')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(newConfig => {
                    newConfig.useMockToken = false;
                    setConfig(newConfig);
                    RtpApi.setUri(newConfig.apiUri);
                    window.parent.postMessage('ready', newConfig.iframeOrigin)
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching config:', error);
                    setLoading(false);
                });
        }
    }, []);

    if (isLoading) {
        return <div>Loading configuration...</div>;
    }

    return (
        <ConfigContext.Provider value={config!}>
            {children}
        </ConfigContext.Provider>);
};
