import React, {useEffect, useState} from 'react';
import {useAuth} from '../../common/contexts/AuthContext';
import {RtpApi} from '../../common/services/RtpApi';

interface EchoTest {
    messageString: string;
}

const HealthCheck: React.FC = () => {
    const [echoMessage, setEchoMessage] = useState<string>('Loading...');
    const [responseTime, setResponseTime] = useState<number | null>(null);
    const {token} = useAuth();

    useEffect(() => {
        const startTime = Date.now();
        RtpApi.request<EchoTest>('/EchoTest', {
            method: 'POST',
            body: JSON.stringify({message: 'Echo Response'})
        }).then(response => {
            setEchoMessage(RtpApi.extractData(response).messageString);
            setResponseTime(Date.now() - startTime);
        }).catch(error => {
            console.error('Failed to fetch echo test:', error);
            setEchoMessage('Failed to fetch echo message.');
            setResponseTime(Date.now() - startTime);
        });
    }, []);

    return (
        <div>
            <h1>Health Check Placeholder</h1>
            <div>Token: {token ? '[Token Provided]' : 'Warning: No token received'}</div>
            <div>API Status: {echoMessage ? 'Online' : 'Offline'}</div>
            <div>API Response Time: {responseTime ? `${responseTime} ms` : 'Calculating...'}</div>
        </div>);
};

export default HealthCheck;