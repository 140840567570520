import { ReportDefinition } from "../types/ReportLibraryTypes";
import ReportRequestRow from './ReportRequestsRow';
import PropTypes from 'prop-types';

interface ReportRequestsTableProps {
    reports: ReportDefinition[];
    onDeleteRequest: (reportId: string, requestId: string | undefined) => void;
}

const ReportRequestsTable: React.FC<ReportRequestsTableProps> = ({
    reports,
    onDeleteRequest
}) => {
    // Header placeholder + request rows
    return (
        <div className="mt-8">
            <h2 className="text-lg font-semibold">Generated Reports</h2>
            <div className="mt-4 grid gap-4 text-sm font-medium text-gray-900"
                 style={{gridTemplateColumns: 'minmax(200px, auto) minmax(230px, auto) 1fr'}}>
                <div className="flex items-center gap-2"></div>
                <div></div>
                <div className="flex justify-between"></div>
            </div>
            {reports.map(report => report.requests.map(request => (
                <ReportRequestRow key={request.reportRequestId} request={request} reportName={report.reportName}
                                  onDeleteRequest={() => onDeleteRequest(report.reportId, request.reportRequestId)}
                />)))}
            <div></div>
        </div>);
};

ReportRequestsTable.propTypes = {
    reports: PropTypes.array.isRequired,
    onDeleteRequest: PropTypes.func.isRequired,
};

export default ReportRequestsTable;
