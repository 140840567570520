class PaginationService {
    private pendingLoadRanges: Set<string> = new Set();
    private loading: boolean = false;
    private totalRecords: number = 0;

    /**
     * Check if the loading state is active
     */
    isLoading() {
        return this.loading;
    }

    /**
     * Set the loading state
     */
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    /**
     * Handle setting the total records count.
     */
    setTotalRecords(count: number) {
        this.totalRecords = count;
    }

    /**
     * Get total records count.
     */
    getTotalRecords() {
        return this.totalRecords;
    }

    /**
     * Check if a range of records is already being loaded.
     */
    isRangePending(rangeKey: string) {
        return this.pendingLoadRanges.has(rangeKey);
    }

    /**
     * Mark a range as pending to prevent duplicate loads.
     */
    addPendingRange(rangeKey: string) {
        this.pendingLoadRanges.add(rangeKey);
    }

    /**
     * Clear all pending ranges.
     */
    clearPendingRanges() {
        this.pendingLoadRanges.clear();
    }

    /**
     * Remove a range from pending load list after successful loading.
     */
    removePendingRange(rangeKey: string) {
        this.pendingLoadRanges.delete(rangeKey);
    }
}

export default PaginationService;
