import * as React from 'react';
import { DateRangePreset, ReportParameter } from "../../features/reportLibrary/types/ReportLibraryTypes";
import { SchemaTypes } from '../../features/reportLibrary/enums/ReportLibrary';
import {
    CancelDialogIcon, 
    ChevronDownIcon, 
    ChevronLeftIcon, 
    ChevronRightIcon, 
    InfoIcon
} from "./RtpIcons";
import { Calendar } from "primereact/calendar";
import { FormEvent } from "primereact/ts-helpers";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";

interface InputProps {
    parameter: ReportParameter;
    onChange: (name: string, value: string) => void;
    value: string | string[];
    selectedDatePreset?: DateRangePreset;
    isLoading: boolean;
}

const Input: React.FC<InputProps> = ({
    parameter,
    onChange,
    value,
}) => {

    const handleDateChange = (e: FormEvent<Date | Date[]>) => {
        const date = e.target.value as Date | null;
        if (date) {
            onChange(parameter.name, formatDate(date));
        } else {
            onChange(parameter.name, '');
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        onChange(parameter.name, event.target.value);
    };

    const handleStringChange = (value: string) => {
        onChange(parameter.name, value);
    };

    const formatDate = (date: Date): string => {
        const month = (
            date.getMonth() + 1).toString().padStart(2, '0'); // Ensuring two digits
        const day = date.getDate().toString().padStart(2, '0'); // Ensuring two digits
        const year = date.getFullYear();
        return `${year}-${month}-${day}`; // Returning in 'YYYY-MM-DD' format
    };

    const getComponent = () => {
        if (parameter.schema.isArray) {
            return <MultiSelect
                value={Array.isArray(value) ? value : [value]}
                onChange={(e: MultiSelectChangeEvent) => handleStringChange(e.value)}
                options={parameter.options ? parameter.options.sort((a, b) => a.label.localeCompare(b.label)) : []}
                optionLabel="label"
                optionValue="value"
                filter
                placeholder="Select"
                pt={{
                    root: {className: "border rounded h-12 w-full !shadow-none"},
                    input: {className: '  w-full'},
                    panel: {className: 'border rounded'},
                    headerCheckboxContainer: {className: `[&>div[data-p-highlight='true']>div]:bg-[#008D76] [&>div[data-p-highlight='true']>div>svg]:stroke-white`},
                    checkbox: {icon: {className: `[&[data-p-highlight='true']]:stroke-white`}},
                    item: {className: `[&[data-p-highlight='true']]:bg-[#008D76]/10 [&[data-p-highlight='true']]:text-[#008D76] [&[data-p-highlight='true']>div>div>div]:bg-[#008D76] [&[data-p-highlight='true']>div>div>div>svg]:stroke-white`}
                }}
                closeIcon={<CancelDialogIcon/>}
                className="w-full md:w-20rem"/>
        } else switch (parameter.schema.type) {
            case SchemaTypes.Date:
                return <Calendar
                    className="w-full"
                    dateFormat='yy-mm-dd'
                    inputClassName='focus:outline-none w-full'
                    inputId={parameter.name}
                    nextIcon={<ChevronRightIcon/>}
                    onChange={handleDateChange}
                    placeholder="Select"
                    prevIcon={<ChevronLeftIcon/>}
                    pt={{
                        root: {className: "border rounded h-12 px-2 shadow-none"}
                    }}
                    value={parseDate(value)}
                />
            case SchemaTypes.Uuid:
                return <Dropdown
                    className="w-full md:w-14rem"
                    defaultValue={value}
                    dropdownIcon={<ChevronDownIcon/>}
                    filter={true}
                    inputId={parameter.name}
                    onChange={e => handleStringChange(e.value)}
                    options={parameter.options ? parameter.options.sort((a, b) => a.label.localeCompare(b.label)) : []}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select"
                    pt={{
                        root: {className: "border rounded h-12 w-full !shadow-none"},
                        input: {className: '  w-full'},
                        item: {className: `[&[data-p-highlight='true']]:bg-[#008D76]/10 [&[data-p-highlight='true']]:text-[#008D76]`}
                    }}
                    value={value}
                />
            default:
                return <input type="text" value={value} onChange={handleInputChange}/>;
        }
    };

    const parseDate = (dateString: string | string[] | undefined): Date | undefined => {
        if (!dateString) return undefined;
        return new Date(dateString + 'T00:00:00');
    };

    return (
        <div key={parameter.name}>
            {parameter.description && <Tooltip hideDelay={1000} target={'#' + parameter.name + '_label'}
                                               autoHide={false} event='hover'>
                {parameter.description}
            </Tooltip>}
            <label id={parameter.name + '_label'}
                   htmlFor={parameter.name}>{parameter.displayName} {parameter.required &&
                <span className="text-danger">*</span>} {parameter.description &&
                <span className="inline-block"><InfoIcon className="w-4 h-4"/></span>}
            </label>
            <div className="card flex justify-content-center">
                {getComponent()}
            </div>
        </div>);
};

export default Input;
