import {useEffect, useState} from 'react';
import {ReportDefinition} from '../types/ReportLibraryTypes';
import {RtpApi} from '../../../common/services/RtpApi';
import {useAuth} from '../../../common/contexts/AuthContext';

type UseReportsReturn = {
    reports: ReportDefinition[];
    isLoading: boolean;
    error: string | null;
    refreshReports: () => void;
    removeRequestFromReport: (reportId: string, requestId: string | undefined) => void;
}

export const useReports = (): UseReportsReturn => {
    const [reports, setReports] = useState<ReportDefinition[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const {token} = useAuth();

    const fetchReports = async () => {
        setIsLoading(true);
        try {
            const response = await RtpApi.request<ReportDefinition[]>('/DataQuery/Reports');
            setReports(RtpApi.extractData(response));
            setError(null);
        }
        catch (err) {
            setError('Failed to fetch reports');
            console.error(err);
            throw err;
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (token) {
            fetchReports();
        }
    }, [token]);

    // Polling logic to refresh reports that are in progress
    useEffect(() => {
        const interval = setInterval(async () => {
            const inProgressReports = reports.filter(report => report.requests.some(req => req.status.key ===
                'InProgress' ||
                req.status.key ===
                'Started'));

            if (inProgressReports.length > 0) {
                try {
                    const updatedReports = await RtpApi.request<ReportDefinition[]>('/DataQuery/Reports');
                    setReports(RtpApi.extractData(updatedReports));
                }
                catch (error) {
                    console.error('Failed to refresh reports:', error);
                }
            }
        }, 5000); // 5 seconds

        return () => clearInterval(interval);
    }, [reports]);

    // Remove a "deleted" report from the list
    const removeRequestFromReport = (reportId: string, requestId: string | undefined) => {
        if (!requestId) return;
        setReports(currentReports => currentReports.map(report => {
            if (report.reportId === reportId) {
                return {
                    ...report,
                    requests: report.requests.filter(request => request.reportRequestId !== requestId)
                };
            }
            return report;
        }));
    };

    return {
        reports,
        isLoading,
        error,
        refreshReports: fetchReports,
        removeRequestFromReport
    };
}
