import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HealthCheck from '../features/health/HealthCheck';
import LandingPage from '../features/landingPage/LandingPage';
import {AuthProvider} from '../common/contexts/AuthContext';
import {ConfigProvider} from "../common/contexts/ConfigContext";
import Admin from "../features/admin/Admin";
import {PrimeReactProvider} from "primereact/api";
import ReportLibrary from '../features/reportLibrary/ReportLibrary';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

const App: React.FC = () => {
    return (
        <Router>
            <ConfigProvider>
                <AuthProvider>
                    <PrimeReactProvider>
                        <Routes>
                            <Route path="/admin" Component={Admin}/>
                            <Route path="/reports" Component={ReportLibrary}/>
                            <Route path="/health-check" Component={HealthCheck}/>
                            <Route path="/" Component={LandingPage}/>
                        </Routes>
                    </PrimeReactProvider>
                </AuthProvider>
            </ConfigProvider>
        </Router>);
};

export default App;
