import {ApiResponse} from "../types/ApiResponse";
import {RtpApi} from "./RtpApi";

export class EntityService {
    static async getEntities<T>(
        entityName: string,
        limit: number = 10,
        sort: string = 'id asc',
        offset: number,
        activeOnly: boolean = true,
        filter?: string): Promise<ApiResponse<T[]>> {
        const queryParams = {
            limit: limit.toString(),
            sort,
            activeOnly,
            offset,
            filter: filter?.trim() || undefined
        };

        const queryString = Object.entries(queryParams)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
            .join('&');

        const response = await RtpApi.request<T[]>(`/entity/${entityName}?${queryString}`, {method: 'GET'});

        // Assert the type or throw an error if the response is not ApiResponse<T[]>
        if ('data' in response) {
            return response as ApiResponse<T[]>;
        }

        throw new Error('Unexpected response format: Expected JSON response, but received Blob.');

    }
}
