import React, {useEffect, useState} from 'react';
import CompanyGrid from "./CompanyGrid";

const Admin: React.FC = () => {
    const [selectedTable, setSelectedTable] = useState<string>('company');

    const handleMenuClick = (table: string) => {
        setSelectedTable(table);
    };

    useEffect(() => {
        document.title = 'Roadmap To Profitability: Administration';
    }, []);

    return (
        <div className="flex h-screen">
            {/* Menu on the left */}
            <div className="w-64 bg-gray-100 p-4">
                <h3 className="text-lg font-semibold mb-4">Tables</h3>
                <ul>
                    {['company', 'otherEntity'].map((table) => (
                        <li
                            key={table}
                            className={`cursor-pointer mb-2 ${selectedTable === table ? 'font-bold' : ''}`}
                            onClick={() => handleMenuClick(table)}
                        >
                            {table.charAt(0).toUpperCase() + table.slice(1)}
                        </li>))}
                </ul>
            </div>

            {/* Content Area on the right */}
            <div className="flex-grow p-4">
                {selectedTable === 'company' && <CompanyGrid/>}
            </div>
        </div>);
};

export default Admin;