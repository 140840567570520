import {useEffect, useState} from "react";
import {DateRangePreset} from "../types/ReportLibraryTypes";
import {RtpApi} from "../../../common/services/RtpApi";


export const useDateRangePresets = (shouldLoad: boolean, reportId: string) => {
    const [datePresets, setDatePresets] = useState<DateRangePreset[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (shouldLoad) {
            setLoading(true);
            RtpApi.request<DateRangePreset[]>(`/DataQuery/${reportId}/DateRangePresets`)
                .then(response => setDatePresets(RtpApi.extractData(response)))
                .catch(err => {
                    console.error('Failed to fetch date presets:', err);
                    setError('Failed to load date presets');
                })
                .finally(() => setLoading(false));
        }
    }, [shouldLoad]);

    return {
        datePresets,
        isLoading,
        error
    };
};

export default useDateRangePresets;