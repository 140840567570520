import camelcaseKeys from 'camelcase-keys';
import {ApiResponse} from "../types/ApiResponse";

export class RtpApi {
    static onUnauthorized: (() => void) | null = null;
    private static apiUri: string;
    private static token: string;
    private static apiKey: string;

    static setUri(apiUri: string,) {
        RtpApi.apiUri = apiUri;
    }

    static setToken(token: string) {
        RtpApi.token = token;
    }

    static setApiKey(mockApiKey: string) {
        RtpApi.apiKey = mockApiKey;
    }

    // Helper function to extract data or handle Blob
    static extractData<T>(response: Blob | ApiResponse<T> | undefined): T {
        if (response === undefined) {
            console.error('No response received');
            return {} as T;
        }
        if ('data' in response) {
            return response.data;
        }
        console.log('No data in response, returning response as is');
        return response as T;
    }

    static async request<T extends object>(
        endpoint: string,
        options: RequestInit = {},
        responseType: 'json' | 'blob' = 'json'): Promise<ApiResponse<T> | Blob> {
        // If headers are an instance of Headers, use them directly, otherwise create a new Headers object
        const headers = options.headers instanceof Headers ? options.headers : new Headers(options.headers);

        // Set Content-Type for PATCH method if not already set
        if (options.method === 'PATCH') {
            headers.set('Content-Type', 'application/json-patch+json');
        } else {
            // Default Content-Type for other methods
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
        }

        // Add Authorization and API key headers if available
        if (RtpApi.token) {
            headers.set('Authorization', `Bearer ${RtpApi.token}`);
        }
        if (RtpApi.apiKey) {
            headers.set('x-api-key', RtpApi.apiKey);
        }

        const config = {
            ...options,
            headers
        };

        // Determine what URL to use
        const isFullUrl = /^https?:\/\//i.test(endpoint);
        const url = isFullUrl ? endpoint : `${RtpApi.apiUri}${endpoint}`;

        const response = await fetch(url, config);

        if (response.status === 401) {
            console.error('API call failed: Unauthorized (401)');
            if (RtpApi.onUnauthorized) {
                RtpApi.onUnauthorized();
            }
            throw new Error('Unauthorized: Please check your credentials.');
        }
        if (!response.ok) {
            const body = await response.text();
            console.error(`API call failed: ${response.status} ${response.statusText} ${body}`);
            throw new Error(`API call failed: ${response.status} ${response.statusText}`);
        }

        if (responseType === 'blob') {
            return await response.blob();
        }

        const contentLength = response.headers.get('Content-Length');
        if (contentLength === '0' || response.status === 204 || response.status === 304) {
            // Return default empty response if there's no content
            return {
                data: null,
                totalCount: null,
                cursor: null
            };
        }

        const result = await response.json();

        const camelCasedResult = camelcaseKeys(result, {deep: true});

        // The response is an ApiResponse if it has a 'value' property
        if ('value' in result) {
            return camelCasedResult.value as ApiResponse<T>;
        }

        // Otherwise, return the response as is
        return {
            data: camelCasedResult,
            totalCount: null,
            cursor: null
        };
    }
}

export enum HttpMethod {
    GET = 'GET', POST = 'POST', PUT = 'PUT', DELETE = 'DELETE', PATCH = 'PATCH'
}