import { MouseEvent, useContext, useState } from 'react';
import { ReportDefinition } from '../types/ReportLibraryTypes';
import ReportForm from "./ReportForm";
import { ReportLibraryContext } from '../contexts/ReportLibraryContext';
import { ReportItemDefaultIcon } from '../../../common/components/RtpIcons';
import { Tooltip } from "primereact/tooltip";

interface ReportCardProps {
    report: ReportDefinition,
    refreshReports: () => void
}

const ReportCard: React.FC<ReportCardProps> = ({
    report,
    refreshReports
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const {assetsUri} = useContext(ReportLibraryContext);
    const currentTime = new Date().getTime();
    const reportFormatStrings = report.reportFormats.map(el => el.displayName).join(', ');

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsDialogOpen(!isDialogOpen);
    };

    const handleCloseForm = () => {
        setIsDialogOpen(false);
        refreshReports();
    }

    const reportIcon = (_report: ReportDefinition) => {
        return _report.iconFileName ? (
            <img src={`${assetsUri}/${_report.iconFileName}`} alt="Report icon" className="w-6 h-6"/>) : (
            <ReportItemDefaultIcon/>);
    };

    return (
        <div className="report-card bg-gray-100/50 border flex flex-col justify-between gap-2 p-4 rounded h-56 relative box-border">
            <div>
                <div
                    className="  border flex h-10 items-center justify-center left-2 rounded-full w-10">
                    {reportIcon(report)}
                </div>
                <div className="text-gray-500">{report.reportName}</div>
                <Tooltip target={'#id_' + currentTime} event="hover" position="mouse">
                    {report.description}
                </Tooltip>
                <div id={'#id_' + currentTime} className="text-gray-500 text-xs">{report.description}</div>
            </div>
            <div className="flex flex-col justify-end text-sm gap-1">
                <div>
                    {report.reportFormats.length >
                        0 &&
                        (
                            <>
                                <div className="text-gray-400">Available Formats</div>
                                <div className="text-gray-600">{reportFormatStrings}</div>
                            </>)}
                </div>
                <button onClick={handleButtonClick}
                        className="w-full py-2 from-3% to-97% rounded border bg-[#008D76] text-xs uppercase text-gray-50 opacity-90 transition-opacity hover:opacity-100 hover:shadow-md">
                    Create Report
                </button>
            </div>
            {isDialogOpen &&
                (
                    <ReportForm
                        report={report}
                        closeForm={handleCloseForm}
                    />)}
        </div>);
};

export default ReportCard;