import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

export const ReportItemDefaultIcon = () => {
    return (<svg viewBox="0 0 24 25" className="w-6 h-6 stroke-[#008D76]">
        <path
            d="M 3.75 3.118 V 14.368 C 3.75 14.965 3.987 15.537 4.409 15.959 C 4.831 16.381 5.403 16.618 6 16.618 H 8.25 M 3.75 3.118 H 2.25 M 3.75 3.118 H 20.25 M 8.25 16.618 H 15.75 M 8.25 16.618 L 7.25 19.618 M 20.25 3.118 H 21.75 M 20.25 3.118 V 14.368 C 20.25 14.965 20.013 15.537 19.591 15.959 C 19.169 16.381 18.597 16.618 18 16.618 H 15.75 M 15.75 16.618 L 16.75 19.618 M 7.25 19.618 H 16.75 M 7.25 19.618 L 6.75 21.118 M 16.75 19.618 L 17.25 21.118 M 7.5 12.118 L 10.5 9.118 L 12.648 11.266 C 13.657 9.818 14.972 8.608 16.5 7.723">
        </path>
    </svg>)
}

export const CancelDialogIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-12 h-12 p-4 stroke-[#008D76]">
                <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    );
}

export const ChevronDownIcon = (props: IconProps) => {
    return (<svg {...props} viewBox="0 0 24 24" className="cursor-pointer w-5 stroke-[#008D76]"><path d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path></svg>);
}

export const ChevronLeftIcon = (props: IconProps) => {
    return (<svg {...props} viewBox="0 0 32 32" className="rotate-90 h-8 stroke-[#008D76] w-8">
        <path d="M 21 13.5 L 16 18.5 L 11 13.5"></path>
    </svg>);
}

export const ChevronRightIcon = (props: IconProps) => {
    return (<svg {...props} viewBox="0 0 32 32" className="-rotate-90 h-8 stroke-[#008D76] w-8">
        <path d="M 21 13.5 L 16 18.5 L 11 13.5"></path>
    </svg>);
}

export const InfoIcon = (props: IconProps) => {
    let classList = ' stroke-[#008D76] ';
    if (props.className) {
        classList += props.className;
    }
    return (
        <svg {...props} className={classList} height="100%" viewBox="0 0 16 17" fill="none">
            <path
                fill="none"
                d="M7.42854 7.92829L7.45978 7.91305C7.55747 7.86425 7.66711 7.84446 7.77571 7.85603C7.88431 7.8676 7.98731 7.91006 8.07253 7.97836C8.15775 8.04666 8.22161 8.13795 8.25655 8.24143C8.29149 8.3449 8.29605 8.45622 8.26968 8.5622L7.73025 10.723C7.70369 10.829 7.70811 10.9404 7.74297 11.044C7.77783 11.1476 7.84167 11.2391 7.92693 11.3075C8.01218 11.3759 8.11527 11.4184 8.22396 11.43C8.33265 11.4416 8.44239 11.4218 8.54016 11.3729L8.57139 11.3569M14.8571 8.49972C14.8571 9.40021 14.6797 10.2919 14.3351 11.1238C13.9905 11.9558 13.4854 12.7117 12.8487 13.3485C12.212 13.9852 11.456 14.4903 10.6241 14.8349C9.79213 15.1795 8.90046 15.3569 7.99997 15.3569C7.09947 15.3569 6.2078 15.1795 5.37585 14.8349C4.5439 14.4903 3.78798 13.9852 3.15123 13.3485C2.51449 12.7117 2.00939 11.9558 1.66479 11.1238C1.32019 10.2919 1.14282 9.40021 1.14282 8.49972C1.14282 6.68109 1.86527 4.93695 3.15123 3.65099C4.4372 2.36503 6.18134 1.64258 7.99997 1.64258C9.81859 1.64258 11.5627 2.36503 12.8487 3.65099C14.1347 4.93695 14.8571 6.68109 14.8571 8.49972ZM7.99997 5.64258H8.00606V5.64867H7.99997V5.64258Z" />
        </svg>);
}

export const DeleteFileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4 text-gray-50">
            <path
                d="M9 3h6M4 6h16M7 6v14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6M10 11v6M14 11v6M9 6v-2a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const DownloadFileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4 text-gray-50">
            <path
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}