import React, {createContext, useContext, useEffect, useState} from 'react';
import {RtpApi} from '../services/RtpApi';
import {useConfig} from './ConfigContext';

interface AuthContextType {
    token: string | null;
    setToken: (token: string) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [token, setToken] = useState<string | null>(null);
    const config = useConfig();

    useEffect(() => {
        if (config.useMockToken) {
            setToken('mockToken');
        } else {
            const handleMessage = (event: MessageEvent) => {
                const receivedToken = event.data.userToken;
                if (!receivedToken) {
                    console.log('no token received');
                    return;
                }
                if (event.origin === config.iframeOrigin) {
                    setToken(receivedToken);
                    RtpApi.setToken(receivedToken);
                } else {
                    console.warn('Received message from disallowed origin:', event.origin);
                }
            };
    
            window.addEventListener('message', handleMessage);
            return () => window.removeEventListener('message', handleMessage);
        }
        
    }, [config.iframeOrigin]);

    return (
        <AuthContext.Provider value={{
            token,
            setToken
        }}>
            {children}
        </AuthContext.Provider>);
};
