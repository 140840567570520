import {useEffect, useState} from 'react';
import {ReportParameter} from "../types/ReportLibraryTypes";
import {HttpMethod, RtpApi} from '../../../common/services/RtpApi';

export const useReportParameters = (reportId: string,
    requestParameters: Record<string, string | number | boolean | string[]> | undefined) => {
    const [parameters, setParameters] = useState<ReportParameter[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            console.log('Fetching report parameters', "reportId:", reportId, "queryBody:", requestParameters);
            try {
                const requestOptions = requestParameters ? {
                    method: HttpMethod.POST,
                    body: JSON.stringify(requestParameters)
                } : {method: HttpMethod.GET};

                const responseData = await RtpApi.request<ReportParameter[]>(`/DataQuery/${reportId}/Parameters`,
                    requestOptions);

                setParameters(RtpApi.extractData(responseData));
            }
            catch (err) {
                setError('Failed to load report parameters: ' + err);
                throw err;
            }
            finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [reportId, requestParameters]);

    return {
        parameters,
        isLoadingParameters: loading,
        error
    };
};
