import { createContext, ReactNode, useMemo } from "react";
import { RtpApi } from "../../../common/services/RtpApi";
import { ReportLibraryContextType } from "../types/ReportLibraryTypes";
import PropTypes from 'prop-types';

export const ReportLibraryContext = createContext<ReportLibraryContextType>({
    reloadData: () => { console.error('Reload function not implemented yet'); },
    rtpApi: new RtpApi(),
    assetsUri: ''
});

type ReportLibraryCtxProviderProps = {
    children: ReactNode; 
    rtpApi: RtpApi; 
    assetsUri: string;
};

export const ReportLibraryCtxProvider: React.FC<ReportLibraryCtxProviderProps> = ({
    children,
    rtpApi,
    assetsUri
}) => {
    const reloadData = () => {
        console.error('Data reload not implemented yet');
    };

    const contextValue = useMemo(() => (
        {
            reloadData,
            rtpApi,
            assetsUri
        }), [rtpApi, assetsUri]);
    
    return (
        <ReportLibraryContext.Provider value={contextValue}>
            {children}
        </ReportLibraryContext.Provider>
    );
};

ReportLibraryCtxProvider.propTypes = {
    children: PropTypes.node.isRequired,
    rtpApi: PropTypes.instanceOf(RtpApi).isRequired,
    assetsUri: PropTypes.string.isRequired,
};

