import {Dropdown} from 'primereact/dropdown'; // Import Dropdown
import {ColumnEditorOptions} from "primereact/column";
import {SelectItem} from "primereact/selectitem";

const GridDropDown = ({
    options,
    values
}: { options: ColumnEditorOptions, values: SelectItem[] }) => {
    return (
        <Dropdown
            value={options.value}
            options={values}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => options.editorCallback(e.value)}
        />);
};

export default GridDropDown;
