import { useReports } from "../hooks/useReports";
import { ReportDefinition, ReportLibraryPageProps } from "../types/ReportLibraryTypes";
import Spinner from "../../../common/components/Spinner";
import ReportCard from "../components/ReportCard";
import ReportRequestsTable from "../components/ReportRequestsTable";
import { useEffect, useState } from "react";
import { RtpApi } from "../../../common/services/RtpApi";
import { Dialog } from "primereact/dialog";

const ReportLibraryPage: React.FC<ReportLibraryPageProps> = () => {
    const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState(false);
    const {
        reports,
        isLoading,
        error,
        refreshReports,
        removeRequestFromReport
    } = useReports();

    useEffect(() => {
        RtpApi.onUnauthorized = () => {
            setShowSessionExpiredDialog(true);
        };

        return () => {
            RtpApi.onUnauthorized = null;
        };
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    return ( 
        <div className="report-library-page">
            <div className="grid grid-cols-4 gap-2 grid-flow-row-dense">
                {reports.map((report: ReportDefinition, index: number) => (
                    <ReportCard key={index} report={report} refreshReports={refreshReports}/>))}
            </div>
            <ReportRequestsTable reports={reports} onDeleteRequest={removeRequestFromReport}/>
            <Dialog
                visible={showSessionExpiredDialog}
                onHide={() => setShowSessionExpiredDialog(false)}
                header="Session Expired"
                footer={<button className="p-2 bg-[#008D76] rounded text-xs uppercase text-gray-50 opacity-90 transition-opacity hover:opacity-100 hover:shadow-md" onClick={() => window.location.reload()}>Refresh</button>}
                modal
                closable={false}
            >
                User session has expired. Please refresh the page.
            </Dialog>
        </div>
     );
}

export default ReportLibraryPage;