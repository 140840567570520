import {
    DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData, DataTableSortMeta
} from 'primereact/datatable';
import {GridColumn} from "../types/GridColumn";

class GridHelperService {

    /**
     * Build a sorting string for API calls from the sorting metadata.
     * @param sortMeta - Array of sort metadata objects.
     * @returns A sorting string formatted for the API (e.g., "name asc, companyId desc").
     */
    static buildSortString(sortMeta: DataTableSortMeta[]): string {
        return sortMeta
            .map(meta => `${meta.field} ${meta.order === 1 ? 'asc' : 'desc'}`)
            .join(', ');
    }

    /**
     * Build a filtering string for API calls from the filtering metadata.
     * @param filters - A collection of filter metadata.
     * @param columns - An array of column definitions.
     * @returns A filtering string formatted for the API (e.g., "name.Contains('example') AND status.Equals('active')").
     */
    static buildFilterString(filters: DataTableFilterMeta, columns: GridColumn[]): string {
        const filterExpressions: string[] = [];

        for (const field in filters) {
            const filterMeta = filters[field];
            const gridColumn = columns.find(column => column.field === field);

            if (this.isDataTableFilterMetaData(filterMeta)) {
                if (!filterMeta.value) {
                    continue;
                }
                if (gridColumn?.dataType ===
                    'numeric') filterExpressions.push(`${field}.${filterMeta.matchMode}(${filterMeta.value})`); else filterExpressions.push(
                    `${field}.${filterMeta.matchMode}("${filterMeta.value}")`);
            }

            if (this.isDataTableOperatorFilterMetaData(filterMeta)) {
                filterMeta.constraints.forEach(constraint => {
                    if (constraint.value) {
                        filterExpressions.push(`${field}.Contains("${constraint.value}")`);
                    }
                });
            }
        }

        return filterExpressions.join(' AND ');
    }

    /**
     * Type guard for DataTableFilterMetaData (basic filter).
     * @param meta - The filter metadata to check.
     * @returns True if the metadata is of type DataTableFilterMetaData.
     */
    static isDataTableFilterMetaData(meta: unknown): meta is DataTableFilterMetaData {
        return typeof meta ===
            'object' &&
            meta !==
            null &&
            'value' in
            meta &&
            !(
                'operator' in meta);
    }

    /**
     * Type guard for DataTableOperatorFilterMetaData (advanced filter with operators).
     * @param meta - The filter metadata to check.
     * @returns True if the metadata is of type DataTableOperatorFilterMetaData.
     */
    static isDataTableOperatorFilterMetaData(meta: unknown): meta is DataTableOperatorFilterMetaData {
        return typeof meta === 'object' && meta !== null && 'operator' in meta && 'constraints' in meta;
    }

    /**
     * A helper method to generate a default filter or sorting string if none is provided.
     * @param field - The field name.
     * @param defaultValue - A default value to use if filter/sort is not provided.
     * @returns A formatted filter or sort string.
     */
    static getDefaultFieldFilter(field: string, defaultValue: string): string {
        return `${field}.Equals("${defaultValue}")`;
    }
}

export default GridHelperService;
