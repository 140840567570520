import React, {useEffect, useRef, useState} from 'react';
import {DataTable, DataTableFilterMeta, DataTableSortMeta, DataTableValueArray} from 'primereact/datatable';
import {VirtualScrollerLazyEvent, VirtualScrollerLoadingTemplateOptions} from 'primereact/virtualscroller';
import {Column, ColumnEditorOptions, ColumnEvent} from 'primereact/column';
import {Skeleton} from 'primereact/skeleton';
import DebounceService from '../../common/services/DebounceService';
import PaginationService from '../../common/services/PaginationService';
import GridHelperService from "../../common/services/GridHelperService";
import {InputText} from "primereact/inputtext";
import {RtpApi} from "../services/RtpApi";
import {GridColumn} from "../types/GridColumn";
import {ApiResponse} from "../types/ApiResponse";
import {IApiEntity} from "../types/IApiEntity";
import {FilterMatchMode} from "primereact/api";

const paginationService = new PaginationService();

interface VirtualScrollerGridProps<T extends IApiEntity> {
    columns: GridColumn[];
    fetchData: (limit: number, offset: number, sortString: string, filters: string) => Promise<{
        data: T[], totalCount: number
    }>;
    defaultSortMeta?: DataTableSortMeta[];
}

const VirtualScrollerGrid = <T extends IApiEntity, >({
    columns,
    fetchData,
    defaultSortMeta = [],
}: VirtualScrollerGridProps<T>): React.ReactElement => {
    const [entities, setEntities] = useState<T[]>([]);
    const [multiSortMeta, setMultiSortMeta] = useState<DataTableSortMeta[]>(defaultSortMeta);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingRange, setLoadingRange] = useState(false);
    const dataTableRef = useRef<DataTable<DataTableValueArray>>(null);
    const [initialLoadTriggered, setInitialLoadTriggered] = useState(false);

    // Filter state
    const initialFilters: DataTableFilterMeta = columns.reduce((acc, col) => {
        if (col.filterable && col.defaultFilter) {
            acc[col.field] = col.defaultFilter;
        }
        return acc;
    }, {} as DataTableFilterMeta);
    const [filters, setFilters] = useState<DataTableFilterMeta>(initialFilters);
    const [debouncedFilters, setDebouncedFilters] = useState<DataTableFilterMeta>(filters);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedFilters(filters);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [filters]);

    useEffect(() => {
        if (loading) return;
        resetData();
    }, [debouncedFilters, multiSortMeta]);

    useEffect(() => {
    }, [entities]);

    useEffect(() => {
        if (totalRecords > 0 && initialLoadTriggered) {
            dataTableRef.current?.getVirtualScroller()?.scrollToIndex(0);
            onLazyLoad({
                first: 0,
                last: Math.min(10, totalRecords)
            });
        }
    }, [totalRecords]);

    const initialLoad = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const filterString = GridHelperService.buildFilterString(debouncedFilters, columns);
            const sortString = GridHelperService.buildSortString(multiSortMeta);
            const response = await fetchData(0, 0, sortString, filterString);

            const total = response.totalCount;
            setEntities(Array.from({length: total},
                (_, index) => (
                    {renderIndex: index + 1} as T)));
            setTotalRecords(total);
        }
        catch (error) {
            console.error('Error fetching total count:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const loadingTemplate = (options: VirtualScrollerLoadingTemplateOptions) => {
        return (
            <div className="flex align-items-center" style={{
                height: '46px',
                flexGrow: '1',
                overflow: 'hidden'
            }}>
                <Skeleton width={options.cellEven ? '40%' : '60%'} height="1rem"/>
            </div>);
    };

    const onCellEditCancel = (event: ColumnEvent) => {
        const {
            field,
            rowIndex,
            cellIndex,
            rowData
        } = event;

        // Revert the value to the original if edit is canceled
        rowData[field] = rowData[`original_${field}`];

        // Remove the edit styling
        const cellElement = document.querySelector(`.p-datatable tbody tr:nth-child(${rowIndex +
        1}) td:nth-child(${cellIndex + 1})`);

        if (cellElement) {
            cellElement.classList.remove('border-2', 'border-blue-500', 'bg-blue-100');
        }
    };

    const onCellEditInit = (event: ColumnEvent) => {
        const {
            field,
            rowIndex,
            cellIndex,
            rowData
        } = event;
        rowData[`original_${field}`] = rowData[field];

        const cellElement = document.querySelector(`.p-datatable tbody tr:nth-child(${rowIndex +
        1}) td:nth-child(${cellIndex + 1})`);

        if (cellElement) {
            cellElement.classList.add('border-2', 'border-blue-500', 'bg-blue-100');
        }
    };

    const onCellEditComplete = (event: ColumnEvent) => {
        const {
            rowData,
            newValue,
            field,
            rowIndex,
            cellIndex,
            originalEvent
        } = event;

        // Remove the edit styling
        const cellElement = document.querySelector(`.p-datatable tbody tr:nth-child(${rowIndex +
        1}) td:nth-child(${cellIndex + 1})`);

        if (cellElement) {
            cellElement.classList.remove('border-2', 'border-blue-500', 'bg-blue-100');
        }

        if (typeof newValue === 'string' && newValue.trim().length > 0) {
            rowData[field as keyof T] = newValue;

            const patchRequest = [
                {
                    op: 'replace',
                    path: `/${field}`,
                    value: newValue,
                },];

            RtpApi.request<T>(`/entity/company/${rowData.id}`, {
                method: 'PATCH',
                body: JSON.stringify(patchRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response: ApiResponse<T>) => {
                    setEntities((prevEntities) => {
                        const entityIndex = prevEntities.findIndex((entity) => entity.id === rowData.id);
                        if (entityIndex === -1) return prevEntities;
                        const responseData = RtpApi.extractData(response);

                        const updatedEntity = {
                            ...prevEntities[entityIndex], ...responseData,
                            renderIndex: prevEntities[entityIndex].renderIndex
                        };

                        const updatedEntities = [...prevEntities];
                        updatedEntities[entityIndex] = updatedEntity;
                        return updatedEntities;
                    });
                })
                .catch((error) => console.error('Failed to patch company status', error));
        } else {
            originalEvent.preventDefault(); // Prevent invalid edit
        }
    };

    const onFilter = DebounceService.debounce((event: { filters: DataTableFilterMeta }) => {
        setFilters(event.filters);
    }, 300);

    const onLazyLoad = async (event: VirtualScrollerLazyEvent) => {
        if (totalRecords === 0 || loading) return;

        setInitialLoadTriggered(true);

        setLoadingRange(true);
        const offset: number = event.first as number || 0;
        const limit: number = (
            event.last as number || 0) - offset;

        if (limit <= 0) {
            setLoadingRange(false);
            return;
        }

        const needsFetch = entities.slice(offset, offset + limit).some(entity => !entity?.id);

        if (!needsFetch) {
            setLoadingRange(false);
            return;
        }

        const rangeKey = `${offset}-${event.last}`;
        if (paginationService.isRangePending(rangeKey)) return;

        paginationService.addPendingRange(rangeKey);

        try {
            const sortString = GridHelperService.buildSortString(multiSortMeta);
            const filterString = GridHelperService.buildFilterString(debouncedFilters, columns);
            const response = await fetchData(limit, offset, sortString, filterString);

            if (response.data.length > 0) {
                setEntities((prevEntities) => {
                    const newEntities = [...prevEntities];
                    for (let i = 0; i < response.data.length; i++) {
                        const dataItem = response.data[i];
                        dataItem.renderIndex = offset + i + 1;
                        newEntities[offset + i] = dataItem;
                    }
                    return newEntities;
                });
            }
        }
        catch (error) {
            console.error('Error loading entities:', error);
        }
        finally {
            paginationService.removePendingRange(rangeKey);
            setLoadingRange(false);
        }
    };

    const onSort = async (event: { multiSortMeta: DataTableSortMeta[] }) => {
        if (JSON.stringify(event.multiSortMeta) === JSON.stringify(multiSortMeta)) {
            return;
        }

        setMultiSortMeta(event.multiSortMeta);
    };

    const resetData = () => {
        if (loading) return;
        setEntities([]);
        setTotalRecords(0);
        paginationService.clearPendingRanges();
        if (dataTableRef.current && dataTableRef.current.getVirtualScroller()) {
            dataTableRef.current.getVirtualScroller().scrollToIndex(0);
        }
        initialLoad();
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}/>);
    };

    return (
        <div>
            <DataTable
                value={entities}
                ref={dataTableRef}
                dataKey={'renderIndex'}
                scrollable
                scrollHeight="500px"
                totalRecords={totalRecords}
                virtualScrollerOptions={{
                    lazy: true,
                    onLazyLoad: onLazyLoad,
                    itemSize: 80, // showLoader: true, // Causes duplicate key errors
                    loadingTemplate: loadingTemplate,
                    loading: loadingRange || loading || totalRecords === 0,
                }}
                editMode="cell"
                sortMode="multiple"
                removableSort={false}
                filters={filters}
                filterDisplay="row"
                loading={loading || totalRecords === 0}
                multiSortMeta={multiSortMeta}
                onSort={onSort}
                onFilter={onFilter}
                showGridlines
                stripedRows>
                {columns.map((col) => (
                    <Column key={col.field} field={col.field} header={col.label} sortable={col.sortable}
                            filter={col.filterable}
                        // filterField={col.filterField}
                            filterMatchMode={FilterMatchMode.EQUALS}
                            editor={col.editor ? col.editor : (options) => textEditor(options)}
                            onCellEditInit={onCellEditInit}
                            onCellEditComplete={onCellEditComplete}
                            onCellEditCancel={onCellEditCancel}
                            body={col.body}
                            dataType={col.dataType}
                    />))}
            </DataTable>
        </div>);
};

export default VirtualScrollerGrid;