import React from 'react';
import { useEffect, useState } from 'react';
import { useReportParameters } from '../hooks/useReportParameters';
import Input from '../../../common/components/Input';
import Spinner from '../../../common/components/Spinner';
import { CancelDialogIcon } from '../../../common/components/RtpIcons';
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { DateRangePreset, ReportDefinition, ReportFormat, ReportQuery, ReportRequest} from "../types/ReportLibraryTypes";
import { SchemaTypes } from "../enums/ReportLibrary";
import { useDateRangePresets } from "../hooks/useDateRangePresets";
import { HttpMethod, RtpApi } from "../../../common/services/RtpApi";


interface ReportFormProps {
    report: ReportDefinition;
    closeForm: () => void;
}

interface FormData {
    fileFormat: string;

    [key: string]: string | string[];
}

const ReportForm: React.FC<ReportFormProps> = ({
    report,
    closeForm
}) => {

    const [reportQuery, setReportQuery] = useState<ReportQuery | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        parameters,
        isLoadingParameters,
        error
    } = useReportParameters(report.reportId, reportQuery?.reportParameter);
    const defaultFormat = report.reportFormats.length > 0 ? report.reportFormats[0].key : '';
    const hasDateFields = parameters.some(param => param.schema.type === SchemaTypes.Date);
    const {
        datePresets,
        isLoading: isLoadingPresets
    } = useDateRangePresets(hasDateFields, report.reportId);

    const [loadingDependents, setLoadingDependents] = useState<Record<string, boolean>>({});
    const [formData, setFormData] = useState<FormData>({fileFormat: defaultFormat});
    const [selectedDatePreset, setSelectedDatePreset] = useState<DateRangePreset | undefined>(undefined);
    const [triggerRefetch, setTriggerRefetch] = useState(false);

    // Handle changes that require re-fetching parameters
    useEffect(() => {
        if (!triggerRefetch || isLoadingParameters) {
            return;
        }

        const reportParameters: Record<string, string | number | boolean | string[]> = Object.entries(formData)
            .reduce<Record<string, string | number | boolean | string[]>>((acc, [key, value]) => {
                const param = parameters.find(p => p.name === key);
                if (param && key !== 'fileFormat') {
                    if (param.schema.isArray && value === "") {
                        acc[key] = [];
                    } else {
                        acc[key] = value;
                    }
                }
                return acc;
            }, {});

        setReportQuery({
            reportParameter: reportParameters,
            fileFormat: formData.fileFormat,
            requireFreshReport: true
        });

        setLoadingDependents(prev => {
            return Object.keys(prev).reduce<Record<string, boolean>>((acc, key) => {
                acc[key] = true;
                return acc;
            }, {});
        });

        setTriggerRefetch(false);
    }, [triggerRefetch, formData, isLoadingParameters]);

    // Handle updates to formData based on parameters loaded
    useEffect(() => {
        if (isLoadingParameters) {
            return;
        }

        const updatedFormData = {...formData};
        let shouldUpdateFormData = false;

        parameters.forEach(param => {
            let currentValues = formData[param.name];
            if (!Array.isArray(currentValues)) {
                currentValues = [currentValues];
            }

            if (param.isDependent && currentValues) {
                const validOptions = currentValues.filter(value => param.options.some(option => option.value ===
                    value));

                if (validOptions.length !== currentValues.length) {
                    updatedFormData[param.name] = param.schema.isArray ? validOptions : validOptions[0] || '';
                    shouldUpdateFormData = true;
                }
            } else if (param.isDependent) {
                updatedFormData[param.name] = param.schema.isArray ? [] : '';
                shouldUpdateFormData = true;
            }

            if (shouldUpdateFormData) {
                setFormData(updatedFormData);
            }

            // Reset loading indicators for dependent fields once the data is updated
            setLoadingDependents(prev => {
                return Object.keys(prev).reduce<Record<string, boolean>>((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {});
            });
        });
    }, [parameters, isLoadingParameters]);

    // Set default file format
    useEffect(() => {
        if (!formData.fileFormat && report.reportFormats.length > 0) {
            setFormData(prev => (
                {
                    ...prev,
                    fileFormat: report.reportFormats[0].key
                }));
        }
    }, [report.reportFormats, formData.fileFormat]);

    // Set default parameter values
    useEffect(() => {
        if (isLoadingPresets) return;
        if (!selectedDatePreset) {
            setSelectedDatePreset(datePresets.find(preset => preset.isDefault) || datePresets[0]);
        }
        const initialFormData = parameters.reduce<FormData>((acc, param) => {
            if (param.schema.isArray) {
                acc[param.name] = param.options.map(option => option.value);
            } else if (param.schema.type === SchemaTypes.Uuid) {
                acc[param.name] = param.options.find(opt => opt.isDefault)?.value || param.options[0]?.value || '';
            } else if (param.schema.type === SchemaTypes.Date && datePresets.length > 0) {
                const effectivePreset = selectedDatePreset || datePresets[0];
                acc[param.name] =
                    param.name === "startDate" ? formatDate(new Date(effectivePreset.startDate)) : param.name ===
                    "endDate" ? formatDate(new Date(effectivePreset.endDate)) : '';
            } else {
                acc[param.name] = '';
            }
            return acc;
        }, {} as FormData);

        setFormData(initialFormData);
    }, [isLoadingPresets]);

    const formatDate = (date: Date): string => {
        const month = (
            date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensuring two digits
        const day = date.getUTCDate().toString().padStart(2, '0'); // Ensuring two digits
        const year = date.getUTCFullYear();
        return `${year}-${month}-${day}`; // Returning in 'YYYY-MM-DD' format
    };

    const handleInputChange = (name: string, value: string) => {
        setFormData(prev => (
            {
                ...prev,
                [name]: value
            }));
        const param = parameters.find(p => p.name === name);
        if (param &&
            (
                param.hasDependentOptions || param.isDependent)) {
            setLoadingDependents(prev => (
                {
                    ...prev,
                    [param.name]: isLoadingPresets
                }));
            setTriggerRefetch(true);
        }
    };

    const handlePresetChange = (preset: DateRangePreset) => {
        if (selectedDatePreset === preset) { 
            setSelectedDatePreset(undefined); 
        } else {
            setSelectedDatePreset(preset);
        }
        if (preset.startDate) {
            handleInputChange("startDate", formatDate(new Date(preset.startDate)));
        }
        if (preset.endDate) {
            handleInputChange("endDate", formatDate(new Date(preset.endDate)));
        }
    };

    if (error) return <div>{error}</div>;

    const handleRadioChange = (key: string) => {
        setFormData(prev => (
            {
                ...prev,
                fileFormat: key
            }));
    };

    const handleSubmit = async () => {
        if (!allRequiredFieldsFilled()) {
            console.error('Not all required fields are filled.');
            return;
        }
        setIsSubmitting(true);

        const queryParam = {
            fileFormat: formData.fileFormat,
        }
        const queryString = new URLSearchParams(queryParam).toString();

        try {
            const apiResponse = await RtpApi.request<ReportRequest>(`/DataQuery/${report.reportId}?${queryString}`,
                {
                    method: HttpMethod.POST,                 
                    body: JSON.stringify(formData),          
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            const submissionResponse = RtpApi.extractData<ReportRequest>(apiResponse);

            if (submissionResponse.pollingUri === null) {
                console.error('Error while submitting report request:', apiResponse);
            } else {
                setIsSubmitting(false);
                closeForm();
            }
        }
        catch (error) {
            setIsSubmitting(false);
            console.error('Failed to submit report:', error);
        }
    };

    if (error) return <div>{error}</div>;

    const allRequiredFieldsFilled = () => {
        return parameters.every(param => {
            return !param.required ||
                (
                    formData[param.name] && formData[param.name].length > 0);
        }) && formData.fileFormat && formData.fileFormat.length > 0;
    };

    const cancelBtn = () => {
        return <button type="button"
                       onClick={closeForm}
                       className="from-3% px-6 py-4 to-97% rounded border bg-[#008D76]/10 text-xs uppercase  opacity-90 transition-opacity hover:opacity-100 hover:shadow-md">
            Cancel
        </button>
    }

    const header = (reportName: string) => {
        return <div>
            <div>New {reportName}</div>
        </div>;
    }
    const datePresetsContent = () => {
        return <div className="gap-2 grid grid-cols-3 py-2 ">
            {datePresets.map((preset, index) => {
                let highlighted = false;
                if ((
                        !selectedDatePreset && preset.isDefault) ||
                    (
                        selectedDatePreset && preset.label === selectedDatePreset.label)) {
                    highlighted = true;
                }
                return <div key={index}
                            className={`hover:shadow border flex items-center justify-center rounded text-xs p-1 cursor-pointer ${highlighted
                                ? 'bg-[#008D76]/10'
                                : ''}`}
                            onClick={() => {
                                if (!isLoadingPresets) {
                                    handlePresetChange(preset)
                                }
                            }}>{preset.label}</div>
            })}
        </div>
    };

    let presetsRendered = false;

    const renderedFormatOptions = () => {
        return <ReportFormatOptions
            formats={report.reportFormats}
            selectedFormat={formData.fileFormat}
            handleRadioChange={handleRadioChange}
        />
    }

    // function parseCalendarDate(value: string | string[] | Date | null): Date | null {
    //     if (Array.isArray(value)) {
    //         console.error("Received an array for a date field, which is unexpected:", value);
    //         return null;
    //     } else if (value instanceof Date) {
    //         return value;
    //     } else if (typeof value === 'string' && value) {
    //         return new Date(value);
    //     }
    //     return null;
    // }

    const renderedParameters = parameters.map((param, index) => {
        const commonProps = {
            parameter: param,
            onChange: handleInputChange,
            value: formData[param.name],
            selectedDatePreset: selectedDatePreset,
            isLoading: loadingDependents[param.name] || false
        };

        // Handling date presets rendering only once
        if (param.schema.type === SchemaTypes.Date && !presetsRendered) {
            presetsRendered = true;
            return (
                <React.Fragment key={index}>
                    {datePresetsContent()}
                    <Input {...commonProps} />
                </React.Fragment>);
        }

        return <Input key={index} {...commonProps}/>;
    });

    const submitBtn = () => {
        return <button type="button"
                       disabled={!allRequiredFieldsFilled()}
                       onClick={handleSubmit}
                       className="flex from-3% px-6 py-4 to-97% rounded border bg-[#008D76] text-xs uppercase text-gray-50 opacity-90 transition-opacity hover:opacity-100 hover:shadow-md"
                       style={{
                           display: 'inline-flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           width: 'auto', // Remove fixed width to use padding from class
                           height: 'auto', // Remove fixed height to use padding from class
                       }}>
            {isSubmitting &&
                (
                    <div
                        style={{
                            marginRight: '8px', // Space between spinner and text
                            visibility: isSubmitting ? 'visible' : 'hidden'
                        }}
                    >
                        <Spinner ExcludeText={true} lightColor={true}/>
                    </div>)}
            Submit
        </button>
    }

    const ReportFormatOptions: React.FC<{
        formats: ReportFormat[]; selectedFormat: string; handleRadioChange: (key: string) => void;
    }> = ({
        formats,
        selectedFormat,
        handleRadioChange
    }) => {
        return <div className="flex flex-column gap-3 p-2">
            {formats.map((format) => (
                <div key={format.key} className="flex align-items-center">
                    <RadioButton
                        inputId={format.key}
                        name={"category_" + format.key}
                        value={format.key}
                        onChange={() => handleRadioChange(format.key)}
                        checked={selectedFormat === format.key}
                        pt={{
                            box: {
                                className: `${selectedFormat === format.key
                                    ? 'bg-[#008D76]'
                                    : 'border-2 border-gray-200 hover:border-[#008D76]'}`
                            }
                        }}
                        // pt={{box: {className: 'border-2 border-gray-50'}}}
                        // pt={{box: {className: 'bg-[#008D76]'}}}
                        // className={`p-radiobutton-box ${
                        //     selectedFormat === format.key ? 'bg-[#008D76]' : 'border-2 border-gray-400
                        // hover:border-[#008D76]' }`}
                    />
                    <label htmlFor={format.key} className="ml-2">{format.displayName}</label>
                </div>))}
        </div>;
    }

    return (
        <Dialog
            header={header(report.reportName)}
            position="top"
            visible={true}
            style={{width: '50vw'}}
            dismissableMask={!isLoadingPresets}
            closable={!isLoadingPresets}
            draggable={false}
            closeIcon={<CancelDialogIcon/>}
            footer={() => {
                return <div className="flex justify-end gap-2">
                    {!presetsRendered ? '' : <>
                        {cancelBtn()}
                        {submitBtn()}
                    </>}
                </div>
            }}
            onHide={closeForm}>
            <div className="flex flex-col gap-2">
                {isLoadingPresets && <Spinner />}
                {!isLoadingPresets && parameters && renderedFormatOptions()}
                {!isLoadingPresets && parameters && renderedParameters}
            </div>
        </Dialog>);
};

export default ReportForm;
