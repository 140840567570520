import { useContext } from "react";
import { ReportLibraryContext } from "./contexts/ReportLibraryContext";
import ReportLibraryPage from "./pages/ReportLibraryPage";

const ReportLibrary: React.FC = () => {
    const { rtpApi } = useContext(ReportLibraryContext);
    return ( 
        <ReportLibraryPage rtpApi={ rtpApi }/>
     );
}
 
export default ReportLibrary;